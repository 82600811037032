<template>
  <div>
    <label class="label-modal">{{ title }}</label>
    <v-autocomplete
      :disabled="getIsOrcamentosLoading"
      :loading="getIsOrcamentosLoading"
      @click:clear="clearAuto('orcamento')"
      clearable
      autocomplete="off"
      :items="getOrcamentos"
      :color="$primary"
      v-model="MyObject.orcamento"
      item-text="strdetailsdoc"
      item-value="strcabid"
    ></v-autocomplete>
  </div>
</template>

<script>
export default {
  props: {
    MyObject: Object,
    cols: Number
  },
  data: () => ({
    cpwd: "",
    show: false
  }),
  computed: {
    getOrcamentos() {
      return this.$store.getters["orcamento/getAll"]
    },
    getIsOrcamentosLoading() {
      return this.$store.getters["orcamento/isLoading"]
    },
    title() {
      return this.getIsOrcamentosLoading
        ? this.$t("messages.carregarOrcamentos")
        : this.$t("fields.orcamento")
    }
  },
  methods: {}
}
</script>
